import React, { useState } from 'react'
import clsx from 'clsx'
import {
  AppBar, Divider, Drawer, IconButton, Typography, Toolbar
} from '@material-ui/core'
import { ChevronLeft, Menu } from '@material-ui/icons'

import MainList from './components/MainList'
import SecondaryList from './components/SecondaryList'
import { useStyles } from './styles'

import './Layout.css'

const Layout = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
    , classes = useStyles()

  /* action handlers */
  const openDrawerHander = () => { setIsMenuOpen(true) }
    , closeDrawerHandler = () => { setIsMenuOpen(false) }

  return <div id='layout'>
    <AppBar position='absolute' className={clsx(classes.appBar, isMenuOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton edge='start' color='inherit' aria-label='open drawer' onClick={openDrawerHander}
          className={clsx(classes.menuButton, isMenuOpen && classes.menuButtonHidden)}>
          <Menu />
        </IconButton>
        <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
          CCE
        </Typography>
      </Toolbar>
    </AppBar>

    <Drawer variant='permanent' open={isMenuOpen}
      classes={{ paper: clsx(classes.drawerPaper, !isMenuOpen && classes.drawerPaperClose) }}>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={closeDrawerHandler}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <MainList />
      <Divider />
      <SecondaryList />
    </Drawer>

    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div id='container' className={isMenuOpen ? classes.layoutContainer : ''}>
        {props.children}
      </div>
    </main>
  </div>
}

export default Layout
