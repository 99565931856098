const routes = {
  'home': {
    url: '/',
  },
  'events': {
    url: '/eventos'
  },
  'event': {
    url: '/evento'
  },
  'news': {
    url: '/noticias'
  },
  'sigleNews': {
    url: '/noticia'
  },
  'users': {
    url: '/usuarios'
  },
  'user': {
    url: '/usuario'
  },
  'rooms': {
    url: '/salas'
  },
  'billboards': {
    url: '/billboards'
  },
  'videos': {
    url: '/videos'
  }
}

export const getUrl = routeName => {
  let route = routes[routeName]
  return route ? route.url : '/'
}
