import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Info, Event, Description, Group, MeetingRoom, Panorama, VideoLibrary } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { getUrl } from '../../../utils/routes'

const MainList = props => {
  const onClickHandler = url => {
    if (url !== props.location.pathname)
      props.history.push(url)
  }
  return <List>
    <ListItem button onClick={() => onClickHandler(getUrl('home'))}>
      <ListItemIcon>
        <Info />
      </ListItemIcon>
      <ListItemText primary='Info general' />
    </ListItem>
    <ListItem button onClick={() => onClickHandler(getUrl('users'))}>
      <ListItemIcon>
        <Group />
      </ListItemIcon>
      <ListItemText primary='Usuarios' />
    </ListItem>
    <ListItem button onClick={() => onClickHandler(getUrl('rooms'))}>
      <ListItemIcon>
        <MeetingRoom />
      </ListItemIcon>
      <ListItemText primary='Salas' />
    </ListItem>
    <ListItem button onClick={() => onClickHandler(getUrl('billboards'))}>
      <ListItemIcon>
        <Panorama />
      </ListItemIcon>
      <ListItemText primary='Billboards' />
    </ListItem>
    <ListItem button onClick={() => onClickHandler(getUrl('videos'))}>
      <ListItemIcon>
        <VideoLibrary />
      </ListItemIcon>
      <ListItemText primary='Videos' />
    </ListItem>

    <ListItem button onClick={() => onClickHandler(getUrl('events'))}>
      <ListItemIcon>
        <Event />
      </ListItemIcon>
      <ListItemText primary='Agenda' />
    </ListItem>

    <ListItem button onClick={() => onClickHandler(getUrl('news'))}>
      <ListItemIcon>
        <Description />
      </ListItemIcon>
      <ListItemText primary='Noticias' />
    </ListItem>

  </List>
}
export default withRouter(MainList)
