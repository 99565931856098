import React, { useReducer, useEffect, } from 'react'
import {
  Button, InputLabel, Paper, Switch, Typography,
  FormControl, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core'
import TextField from '../../../baseComponents/TextField'
import { useStyles } from '../../../utils/styles'
import { generateRandomPassCode } from '../../../utils/misc'


const initialState = {
  RoomType: 'main',
  Name: '',
  URL: '',
  Type: '',
  StartsAt: '',
  EndsAt: '',
  PassCodeRequired: false,
  PassCode: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'Name': return { ...state, Name: action.value }
    case 'URL': return { ...state, URL: action.value }
    case 'Type': return { ...state, Type: action.value }
    case 'StartsAt': return { ...state, StartsAt: action.value }
    case 'EndsAt': return { ...state, EndsAt: action.value }
    case 'PassCodeRequired': return { ...state, PassCodeRequired: action.value }
    case 'PassCode': return { ...state, PassCode: action.value }
    case 'room': return { ...state, ...action.value }
  }
}

const types = [
  { name: 'En vivo', value: 'live' },
  { name: 'On demand', value: 'on-demand' },
]

const Room = props => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
    , onFormChange = (type, value) => {
      dispatch({ type, value })
      props.onChange({ ...state, [type]: value }, props.index)
    }

  useEffect(() => {
    dispatch({ type: 'room', value: props.room })
  }, [props.room])

  return <div>
    <Typography variant='h4'>{state.Name}</Typography>

    <div className='field'>
      <TextField fullWidth margin='normal' label='Nombre' value={state.Name}
        onChange={e => onFormChange('Name', e.target.value)}
        placeholder={state.Name} />
    </div>

    <div className='field field-margin-top'>
      <InputLabel className='label-margin'>PassCode</InputLabel>
      <div style={{ alignItems: 'baseline', display: 'flex' }}>
        <InputLabel>No</InputLabel>
        <Switch checked={state.PassCodeRequired} onChange={() => onFormChange('PassCodeRequired', !state.PassCodeRequired)} />
        <InputLabel>Sí</InputLabel>
      </div>
    </div>
    {
      state.PassCodeRequired &&
      <div className='field flex-row'>
        <TextField fullWidth margin='normal' label='PassCode' value={state.PassCode}
          onChange={e => onFormChange('PassCode', e.target.value)}
          placeholder={state.PassCode}
        />
        <Button
          className={classes.btn}
          variant='contained'
          onClick={() => onFormChange('PassCode', generateRandomPassCode(8))}>
          <Typography>Generar</Typography>
        </Button>
      </div>
    }
  </div>
}

export default Room