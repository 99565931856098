import React, { useEffect, useState } from 'react'
import {
  Container, Paper, Typography,
} from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'
import { getUrl } from '../../utils/routes'
import UsersTable from './components/Table'
import Loader from '../../baseComponents/Loader'
import { useStyles } from './styles'

const Users = props => {
  const [users, setUsers] = useState([])
    , [isFetching, setIsFetching] = useState(true)
    , classes = useStyles()

  const onSetUser = (user) => {
    props.history.push(`${getUrl('user')}/${user.Id}`)
  }

  useEffect(() => {
    const checkInRef = fbDatabase.ref('EVENT_CHECK_IN').child('z-999992')
    checkInRef.on('value', snapshot => {
      let checkInSnapshot = snapshot.val()
        , checkInList = []

      for (let Id in checkInSnapshot)
        checkInList.push({ Id, ...checkInSnapshot[Id] })

      setUsers(checkInList)
      setIsFetching(false)
    })
  }, [])

  if (isFetching) return <Loader />

  let amountOfSessions = users
    .map(user => user.checkIn)
    .reduce((a, b) => a + b)

  return <div id='users'>
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Typography>Accessos totales a CCE: {amountOfSessions}</Typography>
      </Paper>
      <UsersTable rows={users} onSetUser={onSetUser} />
    </Container>
  </div>
}

export default Users