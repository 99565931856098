import React, { useContext } from 'react'
import { useStyles } from '../../utils/styles'

import Header from '../../baseComponents/FormHeader'
import { Paper, Button, Typography, } from '@material-ui/core'
import { EventInfoContext } from '../../providers/EventInfoProvider'
import Billboard from './components/Billboard'
import ImageField from '../../baseComponents/ImageField'
import TextField from '../../baseComponents/TextField'


const Billboards = props => {
  const context = useContext(EventInfoContext)
    , { state, mediaState, onMediaFormChange, onSave, onCancel, formValues } = context
    , classes = useStyles()
    , id = 'z-999992'


  const onAddField = () => {
    let indexItem = Object.keys(mediaState).length
    onMediaFormChange({
      ...mediaState,
      [`Poster${indexItem + 1}`]: {
        Available: false,
        index: indexItem,
        FieldName: `MediaField${indexItem + 1}`,
        Image: formValues.PosterURL,
        Video: formValues.PasilloDeLaFama_VideoURL,
        Link: '',
        Type: 'image'
      }
    })
  }

  const onFormFieldChange = value => {
    onMediaFormChange({
      ...mediaState,
      ...value
    })
  }

  const onSubmitEvent = (e) => {
    e.preventDefault()
    onSave(() => { })
  }

  let inputs = Object.keys(mediaState)
    .map(key => ({ FieldKey: key, ...mediaState[key] }))
    .sort((a, b) => a.index - b.index)

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Header mainTitle='Billboards' isNewEvent={false} id={id} />

    <div style={{ width: '100%' }}>
      <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onAddField} variant='contained' color='primary'>
        <Typography>Agregar campo</Typography>
      </Button>
    </div>

    {
      inputs.map((value, index) => {
        return <Paper key={index} className={`${classes.paper} ${classes.roomCard}`}>
          <Billboard
            id={id}
            key={index}
            UnileverFieldName={value.FieldName}
            UnileverType={value.Type}
            UnileverValueImage={value.Image}
            UnileverValueVideo={value.Video}
            UnileverValueLink={value.Link}
            UnileverAvailable={value.Available}
            onChange={onFormFieldChange}
            FieldKey={value.FieldKey}
          />
        </Paper>
      })
    }

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        Actualizar
      </Typography>
    </Button>

    <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
      <Typography>Cancelar</Typography>
    </Button>
  </form>
}

export default Billboards