import React, { useState, } from 'react'
import { Avatar, Button, Paper, Typography } from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import TextField from '../../baseComponents/TextField'
import { authWithEmailAndPassword } from '../../services/firebase'
import { useStyles } from './styles'

import logo from '../../assets/images/logo_white.png'

import './Login.css'

/*
dev@alucinastudio.com
8peNDo3lxx
*/

const Login = props => {
  const [email, setEmail] = useState('')
    , [password, setPassword] = useState('')
    , [error, setError] = useState('')
    , classes = useStyles()
    , onSubmit = e => {
      e.preventDefault()
      authWithEmailAndPassword(email, password, (err, data) => {
        if (err) return setError(err)
      })
    }

  return <div id='login'>
    <img className='logo' src={logo} alt='hoppia_logo' />
    <Paper className={`form-container ${classes.paper}`}>
      <Avatar className={`avatar ${classes.avatar}`}>
        <LockOutlined />
      </Avatar>
      <form onSubmit={onSubmit}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label='Correo electrónico'
          name='email'
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='password'
          label='Contraseña'
          type='password'
          id='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button className='button' type='submit' fullWidth variant='contained'  >
          Iniciar
        </Button>
        <Typography className={classes.error} variant='body2'>{error}</Typography>
      </form>
    </Paper>
  </div>
}

export default Login
