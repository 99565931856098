import React, { useReducer, useEffect, } from 'react'
import {
  Typography, FormControl, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core'
import TextField from '../../../baseComponents/TextField'


const initialState = {
  RoomType: 'main',
  Name: '',
  URL: '',
  Type: '',
  StartsAt: '',
  EndsAt: '',
  PassCodeRequired: false,
  PassCode: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'Name': return { ...state, Name: action.value }
    case 'URL': return { ...state, URL: action.value }
    case 'Type': return { ...state, Type: action.value }
    case 'StartsAt': return { ...state, StartsAt: action.value }
    case 'EndsAt': return { ...state, EndsAt: action.value }
    case 'PassCodeRequired': return { ...state, PassCodeRequired: action.value }
    case 'PassCode': return { ...state, PassCode: action.value }
    case 'room': return { ...state, ...action.value }
  }
}

const types = [
  { name: 'En vivo', value: 'live' },
  { name: 'On demand', value: 'on-demand' },
]

const Room = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
    , onFormChange = (type, value) => {
      dispatch({ type, value })
      props.onChange({ ...state, [type]: value }, props.index)
    }

  useEffect(() => {
    dispatch({ type: 'room', value: props.room })
  }, [props.room])

  return <div>
    <Typography variant='h4'>{state.Name}</Typography>

    <div className='field'>
      <TextField fullWidth margin='normal' label='URL' value={state.URL}
        onChange={e => onFormChange('URL', e.target.value)}
        placeholder={state.URL} />
    </div>
    <div className='field'>
      <FormControl component='fieldset'>
        <RadioGroup
          style={{ flexDirection: 'row', display: 'flex' }}
          value={state.Type} onChange={e => onFormChange('Type', e.target.value)}>
          {
            types.map(type => <FormControlLabel value={type.value} control={<Radio />} label={type.name} />)
          }
        </RadioGroup>
      </FormControl>
    </div>
    {
      state.Type === 'live' &&
      <React.Fragment>
        <div className='field'>
          <TextField fullWidth margin='normal' label='Fecha de inicio'
            type='datetime-local' InputLabelProps={{ shrink: true, }} value={state.StartsAt}
            onChange={e => onFormChange('StartsAt', e.target.value)} placeholder={state.StartsAt}
          />
        </div>

        <div className='field'>
          <TextField fullWidth margin='normal' label='Fecha de fin'
            type='datetime-local' InputLabelProps={{ shrink: true, }} value={state.EndsAt}
            onChange={e => onFormChange('EndsAt', e.target.value)}
            placeholder={state.EndsAt}
          />
        </div>
      </React.Fragment>
    }
  </div>
}

export default Room