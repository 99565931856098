import React, { useEffect, useReducer, useState, createContext } from 'react'
import { fbDatabase } from '../../services/firebase'
import { initialState, reducer } from './formReducer'
import { formValues } from './defaultValues'

import Loader from '../../baseComponents/Loader'
import { Container, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

export const EventInfoContext = createContext({ event: null })

const EventInfoProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
    , [event, setEvent] = useState({})
    , [mediaState, setMediaState] = useState({})
    , [isLoading, setIsLoading] = useState(true)
    , [success, setSuccess] = useState(false)

  const onFormChange = (type, value) => { dispatch({ type, value }) }
    , onMediaFormChange = (value) => {
      setMediaState({ ...mediaState, ...value })
    }
    , onCancel = () => {
      dispatch({ type: 'setEvent', event: event })
      if (event && event.__Media) setMediaState(event.__Media)
    }
    , onSave = (cb = () => null) => {
      let payloadState = { ...state, }

      if (Object.keys(mediaState).length) {
        payloadState = { ...payloadState, __Media: mediaState }
        let MediaData = { ...mediaState }

        for (let key of Object.keys(MediaData)) {
          MediaData[key] = {
            Type: MediaData[key].Type,
            MediaURL: MediaData[key].Type === 'image' ? MediaData[key].Image : MediaData[key].Video,
            Link: MediaData[key].Link,
            Available: MediaData[key].Available
          }
        }
        payloadState = { ...payloadState, Media: MediaData }

      }

      let payload = { ...event, ...payloadState }
      const eventRef = fbDatabase.ref('EVENTS').child('z-999992')
      eventRef.set({
        ...payload
      })
      setSuccess(true)
    }

  useEffect(() => {
    const eventRef = fbDatabase.ref('EVENTS').child('z-999992')
    eventRef.on('value', snapshot => {
      setIsLoading(true)
      let event = snapshot.val()
      setEvent(event)
      dispatch({ type: 'setEvent', event: event })
      if (event && event.__Media) setMediaState(event.__Media)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) return <Loader />

  return <EventInfoContext.Provider
    value={{
      state,
      mediaState,
      formValues,
      onFormChange,
      onMediaFormChange,
      onSave,
      onCancel
    }}
  >
    <Container maxWidth='xl'>
      {props.children}
    </Container>
    <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
      <Alert onClose={() => setSuccess(false)} severity="success">
        Se actualizaron los datos
      </Alert>
    </Snackbar>
  </EventInfoContext.Provider>
}

export default EventInfoProvider
