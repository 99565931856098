import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { getUrl } from './utils/routes'


import AuthProvider from './providers/AuthProvider'
import EventInfoProvider from './providers/EventInfoProvider'
import Layout from './baseComponents/Layout'
import Home from './screens/Home'
import BillBoards from './screens/Billboards'
import Rooms from './screens/Rooms'
import Videos from './screens/Videos'
import Events from './screens/Events'
import News from './screens/News'
import Users from './screens/Users'
import User from './screens/User'

import './App.css'

const THEME = createMuiTheme({
  palette: {
    type: 'dark',
    primary: { light: '#482880', main: '#673ab7', dark: '#8561c5', },
    secondary: { light: '#b2102f', main: '#ff1744', dark: '#ff4569', }
  }
})

const App = () => {
  return <ThemeProvider theme={THEME}>
    <CssBaseline />
    <AuthProvider>
      <Layout>
        <EventInfoProvider>
          <Switch>
            <Route exact path={getUrl('home')} component={Home} />
            <Route exact path={getUrl('rooms')} component={Rooms} />
            <Route exact path={getUrl('billboards')} component={BillBoards} />
            <Route exact path={getUrl('videos')} component={Videos} />
            <Route exact path={getUrl('events')} component={Events} />
            <Route exact path={getUrl('news')} component={News} />
            <Route exact path={getUrl('users')} component={Users} />
            <Route exact path={`${getUrl('user')}/:id`} component={User} />
          </Switch>
        </EventInfoProvider>
      </Layout>
    </AuthProvider>
  </ThemeProvider>
}

export default App
