export const initialState = {
  Title: 'Noticia CCE',
  PosterURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  Text: '',
  URL: '',
  Show: false,
  Date: "2020-09-03T18:00",
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'Title': return { ...state, Title: action.value }
    case 'PosterURL': return { ...state, PosterURL: action.value }
    case 'Text': return { ...state, Text: action.value }
    case 'URL': return { ...state, URL: action.value }
    case 'Show': return { ...state, Show: action.value }
    case 'Date': return { ...state, Date: action.value }
    case 'setEvent': return { ...state, ...action.event }
    default: return initialState
  }
}