import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { fbAuth } from '../../../services/firebase'

const SecondaryList = props => {
  return <List>
    <ListItem button onClick={() => fbAuth.signOut()}>
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      <ListItemText primary='Salir' />
    </ListItem>
  </List>
}
export default withRouter(SecondaryList)