import React, { useContext } from 'react'
import { useStyles } from '../../utils/styles'

import Header from '../../baseComponents/FormHeader'
import { Paper, Button, Typography } from '@material-ui/core'
import { EventInfoContext } from '../../providers/EventInfoProvider'
import ImageField from '../../baseComponents/ImageField'
import TextField from '../../baseComponents/TextField'


const Home = props => {
  const context = useContext(EventInfoContext)
    , { state, onFormChange, onSave, onCancel, formValues } = context
    , classes = useStyles()
    , id = 'z-999992'

  const onSubmitEvent = (e) => {
    e.preventDefault()
    onSave(() => { })
  }

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Header mainTitle='Información general' isNewEvent={false} id={id} />
    <Paper className={classes.paper}>
      <div className='field'>
        <TextField fullWidth margin='normal' label='Título' value={state.Title}
          onChange={e => onFormChange('Title', e.target.value)}
          placeholder={formValues.Title} />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='Detalles' value={state.Details}
          onChange={e => onFormChange('Details', e.target.value)}
          placeholder={formValues.Details}
        />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='Fecha de inicio'
          type='datetime-local' InputLabelProps={{ shrink: true, }} value={state.StartDate}
          onChange={e => onFormChange('StartDate', e.target.value)} placeholder={formValues.StartDate}
        />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='Fecha de fin'
          type='datetime-local' InputLabelProps={{ shrink: true, }} value={state.EndDate}
          onChange={e => onFormChange('EndDate', e.target.value)}
          placeholder={formValues.EndDate}
        />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='URL del auditorio'
          value={state.StreamURL} onChange={e => onFormChange('StreamURL', e.target.value)}
          placeholder={formValues.StreamURL} />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='URL de la sala VIP'
          value={state.StreamURL2} onChange={e => onFormChange('StreamURL2', e.target.value)}
          placeholder={formValues.StreamURL2} />
      </div>

      <ImageField
        id={id}
        label='URL del póster vertical'
        onChange={url => onFormChange('PosterURL', url)}
        placeholder={formValues.PosterURL}
        value={state.PosterURL}
      />

      <div className='field'>
        <ImageField
          id={id}
          label='URL del póster horizontal'
          onChange={url => onFormChange('AltPosterURL', url)}
          placeholder={formValues.PasilloDeLaFama_PosterURL}
          value={state['AltPosterURL'] ? state['AltPosterURL'] : state.PasilloDeLaFama_PosterURL}
        />
      </div>
    </Paper>

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        Actualizar
      </Typography>
    </Button>

    <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
      <Typography>Cancelar</Typography>
    </Button>
  </form>
}

export default Home